var $class="se2--presentation-summary",$name="PresentationSummary",$path="app/components/PresentationSummary/index.js",$this={$class,$name,$path,};import shell from 'app/modules/shell';

import 'app/partials/image';

import initializeSlider from 'app/components/utilities/initializeSlider';
import createSlidesOnKeydownListener from 'app/components/utilities/createSlidesOnKeydownListener';

import isDesktopBreakpoint from 'app/utilities/isDesktopBreakpoint';
import isUsingKeyboard from 'app/utilities/isUsingKeyboard';

import forEach from 'lodash/forEach';

import {
  controlsSelector,
  nextButtonSelector,
  prevButtonSelector,
  slideSelector,
  slidesSelector,
  swiperContainerSelector,
  moreContentShownClassName,
} from './config';

/**
 * The number represends a threshold of the minimum height of the content that is required in
 * order to render the "show more" button.
 *
 * Keep in sync with index.scss > .read-more-content > max-height
 */
const minContentHeightInPixels = 400;

const readMoreContent = 'read-more-content';
const readMoreButtonWrapper = 'read-more-button-wrapper';
const readMoreButtonCaption = 'read-more-button-caption';

const readMoreTextFallback = 'Read more';
const readLessTextFallback = 'Read less';

export default shell.registerComponent($this, ({
  addEventListener, configs, mount,
}) => {
  function showMoreContent(content, buttonCaption) {
    content.classList.remove('more-content-hidden');
    content.classList.add(moreContentShownClassName);
    buttonCaption.innerHTML = buttonCaption.dataset?.readLessText || readLessTextFallback;
    buttonCaption.classList.add('open');
  }

  function hideMoreContent(content, buttonCaption) {
    content.classList.remove(moreContentShownClassName);
    content.classList.add('more-content-hidden');
    buttonCaption.innerHTML = buttonCaption.dataset?.readMoreText || readMoreTextFallback;
    buttonCaption.classList.remove('open');
  }

  function registerReadMoreButton(element) {
    const readMoreButton = element.querySelector(`.${readMoreButtonWrapper}`);

    if (readMoreButton == null) {
      // Read more button has been disabled.
      return;
    }

    const content = element.querySelector(`.${readMoreContent}`);
    const buttonCaption = readMoreButton.querySelector(`.${readMoreButtonCaption}`);

    const contentHeight = content?.clientHeight;

    if (content == null || contentHeight < minContentHeightInPixels) {
      // The content height is lower than the threshold of minContentHeightInPixels. Therefore,
      // we remove the read more button as we consider it "short" content anyway.
      readMoreButton.classList.add(configs.css.classNames.hide);
      content.classList.add('hide-shadow');
      return;
    }

    hideMoreContent(content, buttonCaption);

    addEventListener(readMoreButton, 'click', () => {
      if (!content.classList.contains(moreContentShownClassName)) {
        showMoreContent(content, buttonCaption);
      } else {
        hideMoreContent(content, buttonCaption);
      }
    });
  }

  let slider = null;

  mount((element) => {
    const slideElements = element.querySelectorAll(slideSelector);

    if (slideElements.length) {
      const slidesElement = element.querySelector(slidesSelector);
      const prevButton = element.querySelector(prevButtonSelector);
      const nextButton = element.querySelector(nextButtonSelector);

      if (slideElements.length > 1) {
        element.querySelector(controlsSelector).classList.toggle(configs.css.classNames.hide, isDesktopBreakpoint());
        slider = initializeSlider(element, addEventListener, {
          containerSelector: slidesSelector,
          nextButtonSelector,
          prevButtonSelector,
          slideSelector,
          swiperContainerSelector,
          preventKeyNavigation: true,
        });

        forEach(slideElements, (slide) => {
          const link = slide.querySelector('a');
          slide.setAttribute('role', 'group');
          if (link) {
            link.setAttribute('role', 'link');
            slide.setAttribute('tabindex', 0);
            addEventListener(slide, 'keydown', ({ code }) => {
              if (isUsingKeyboard() && code === configs.keyNames.enter) {
                link.click();
              }
            });
          }
        });

        addEventListener(slidesElement, 'keydown', createSlidesOnKeydownListener(
          prevButton,
          nextButton,
          isDesktopBreakpoint,
        ));

        addEventListener(window, 'resize', slider.onResize);
      }
    }

    registerReadMoreButton(element);
  });
});
